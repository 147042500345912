<template>
  <Banner bg-image="yellow-bg.jpg" talk-img="crisis.png">
    <template v-slot:title>Experiencing a crisis</template>
    <template v-slot:subtitle>
      If you are experiencing a crisis - thoughts of suicide or are concerned
      about a friend or family member who might be you can contact one of the
      numbers below for confidential support and practical advice from a trained
      professional.
    </template>
  </Banner>

  <section class="my-8 call-sect md:my-12 md:py-8">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="mb-6 text-3xl font-bold text-center call-text text-blue">
        <!-- Here are some places you can reach out to for counseling. -->
      </div>
      <div class="grid grid-cols-1 gap-6 md:grid-cols-3">
        <div class="call">
          <div class="font-bold call-ttl text-blue">Healing Lodge</div>
          <a href="tel:403-247-5003" class="flex align-center text-blue">
            <img src="../../assets/images/phone-icon.svg" class="mr-2" />
            <span>403-247-5003 (Weekdays, 9am-5pm)</span>
          </a>
        </div>

        <div class="call">
          <div class="font-bold call-ttl text-blue">
            Calgary Distress Center/Crisis line
          </div>
          <a href="tel:403-266-4357" class="flex align-center text-blue">
            <img src="../../assets/images/phone-icon.svg" class="mr-2" />
            <span>403-266-HELP (4357) (24/7)</span>
          </a>
        </div>

        <div class="call">
          <div class="font-bold call-ttl text-blue">Connecteen</div>
          <a href="tel:403-264-8336" class="flex align-center text-blue">
            <img src="../../assets/images/phone-icon.svg" class="mr-2" />
            <span>Call: 403-264-8336 (24/7)</span> </a
          ><a href="tel:587-333-2724" class="flex mt-2 align-center text-blue">
            <img src="../../assets/images/text-icon.svg" class="mr-2" />
            <span>Text (daily): 587-333-2724</span>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Banner from "@/components/Banner.vue";
export default {
  name: "FindACrisisHotline",
  components: {
    Banner,
  },
};
</script>

<style scoped>
.call-ttl {
  font-size: 22px;
  margin-bottom: 10px;
  line-height: normal;
  min-height: 60px;
}
.call {
  background: #f8f8f8;
  font-size: 18px;
  padding: 30px;
}
</style>
